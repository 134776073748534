import { defineStore } from "pinia";

export const useFormsStore = defineStore("forms", {
  state: () => ({
    formOpened: false,
    contactFormOpened: false,
    hireTeamFormOpened: false,
    registerFloorFormOpened: false,
  }),
  actions: {
    openContactForm() {
      this.contactFormOpened = !this.contactFormOpened;
      this.openFormOverlay();
    },
    openHireTeamForm() {
      this.hireTeamFormOpened = !this.hireTeamFormOpened;
      this.openFormOverlay();
    },
    openRegisterFloorForm() {
      this.registerFloorFormOpened = !this.registerFloorFormOpened;
      this.openFormOverlay();
    },
    openFormOverlay() {
      this.formOpened = !this.formOpened;
    },
    closeForm() {
      this.contactFormOpened = false;
      this.hireTeamFormOpened = false;
      this.registerFloorFormOpened = false;
      this.formOpened = false;
    },
  },
  getters: {
    isContactFormOpened: (state) => state.contactFormOpened,
    isHireTeamFormOpened: (state) => state.hireTeamFormOpened,
    isRegisterFloorFormOpened: (state) => state.registerFloorFormOpened,
    isFormOpened: (state) => state.formOpened,
  },
});
